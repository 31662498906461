<template>
    <div>
        <CustomHeader title="采购数据" @change="getData"></CustomHeader>
        <div class="layout">
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">昨日粉丝关键值</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">新关注人数</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.new_user],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">取消关注人数</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.cancel_user],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
							<br>
                            <div class="item-sub">
                                <div class="item-subtitle">净增关注人数</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.clean_user],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">累计关注人数</div>
                                <dv-digital-flop :config="{number:[list.yesterday_user.total_user],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-box1">
                            <div class="item-title">昨日图文消息关键值</div>
                            <div class="item-content">
                                <div class="item-sub">
                                    <div class="item-subtitle">图文阅读次数</div>
                                    <dv-digital-flop :config="{number:[list.yesterday_article.int_page_read_count],content:'{nt}次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                                <div class="item-sub">
                                    <div class="item-subtitle">微信发文篇数</div>
                                    <dv-digital-flop :config="{number:[list.yesterday_article.wechat_article_count],content:'{nt}次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
								<br>
                                <div class="item-sub">
                                    <div class="item-subtitle">分享转发次数</div>
                                    <dv-digital-flop :config="{number:[list.yesterday_article.share_count],content:'{nt}次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                                <div class="item-sub">
                                    <div class="item-subtitle">微信收藏人数</div>
                                    <dv-digital-flop :config="{number:[list.yesterday_article.add_to_fav_user],content:'{nt}人',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">近30天粉丝变化趋势</div>
                        <div class="item-content">
                            <eCharts :options="option4"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-1>
                    <div class="item-box3">
                        <div class="item-title">全国粉丝分布</div>
                        <div class="item-content">
                            <eCharts :options="option6"></eCharts>
                        </div>
                    </div>
                </dv-border-box-1>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">粉丝来源城市</div>
                        <div class="item-content">
                            <dv-scroll-ranking-board :config="option1" style="width:85%;height:80%" />
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">性别与语言占比</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">粉丝性别占比</div>
                                <dv-active-ring-chart :config="option2"/>
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">粉丝语言占比</div>
                                <dv-active-ring-chart :config="option3" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">近30天图文消息分析</div>
                        <div class="item-content">
                            <eCharts :options="option5"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
    </div>
    </div>
</template>

<script>
    import {getWechatInfo} from "../api/common";
    import eCharts from "../components/echarts"
    import {convertData,convertToLineData} from "../static/js/common";
    import CustomHeader from "../components/CustomHeader";
    import {mapState} from "vuex";
    export default {
        name: "User",
        components:{eCharts,CustomHeader},
        computed:{
            ...mapState(['linear_color','echarts_color1','echarts_color2','echarts_color3','echarts_color4','echarts_color5'])
        },
        data(){
            return{
                list:{
                    yesterday_user:{
                        new_user:0,
                        cancel_user:0,
                        clean_user:0,
                        total_user:0
                    },
                    yesterday_article:{
                        int_page_read_count:0,
                        add_to_fav_user:0,
                        share_count:0,
                        wechat_article_count:0
                    }
                },
                option1:{},//来源城市
                option2:{},//性别分析
                option3:{},//语言分析
                option4:{},//粉丝变化
                option5:{},//消息分析
                option6:{},//地图
                option4_list:[],
                option5_list:[],
                option4_index:0,
                option5_index:0,
            }
        },
        mounted() {
            this.getData()
            setInterval(()=>{
                this.getData()
            },60*1000)
            this.changeDate()
        },
        methods:{
            async getData(e){
                let res= await getWechatInfo(e)
                this.list=res.data
                console.log(res.data)
                this.option4_list = [
                {
                    name: '新增人数',
                    type: 'line',
                    data: res.data['user_30']['new_user'],
                    color: this.echarts_color1
                },
                {
                    name: '取消人数',
                    type: 'line',
                    data: res.data['user_30']['cancel_user'],
                    color: this.echarts_color2
                },
                {
                    name: '净增人数',
                    type: 'line',
                    data: res.data['user_30']['clean_user'],
                    color: this.echarts_color3,
                },
                {
                    name: '累计人数',
                    type: 'line',
                    data: res.data['user_30']['total_user'],
                    color: this.echarts_color4,
                }]
                this.option5_list=[
                    {
                        name: '图文阅读',
                        type: 'line',
                        data: res.data['article_30']['int_page_read_count'],
                        color: this.echarts_color1
                    },
                    {
                        name: '微信发文',
                        type: 'line',
                        data: res.data['article_30']['wechat_read_count'],
                        color: this.echarts_color2
                    },
                    {
                        name: '分享转发',
                        type: 'line',
                        data: res.data['article_30']['share_count'],
                        color: this.echarts_color3
                    },
                    {
                        name: '微信收藏',
                        type: 'line',
                        data: res.data['article_30']['add_to_fav_user'],
                        color:this.echarts_color4
                    },
                ]
                this.option1={
                    data: res.data.city,
                    rowNum:3
                }
                this.option2={
                    data: [
                        {
                            name: '男性',
                            value: res.data['sex']['male']
                        },
                        {
                            name: '女性',
                            value: res.data['sex']['female']
                        },
                        {
                            name: '未知',
                            value: res.data['sex']['no']
                        },
                    ],
                    radius: '65%',
                    activeRadius: '70%',
                    digitalFlopStyle: {
                        fontSize: 14
                    }
                }
                this.option3={
                    data:res.data['language'],
                    radius: '65%',
                    activeRadius: '70%',
                    digitalFlopStyle: {
                        fontSize: 14
                    }
                }
                this.option4 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['新增人数', '取消人数', '净增人数', '累计人数'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            }
                        },
                        grid: {
                            top:'20%',
                            left: '7%',
                            right: '7%',
                            bottom:0,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['user_30']['date']
                        },
                        yAxis: {
                            type: 'value',
                            splitNumber: 2,
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        },
                        series: [{
                            name: '新增人数',
                            type: 'line',
                            data: res.data['user_30']['new_user'],
                            color: this.linear_color
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 10
                                    }
                                ],
                                legend: {
                                    textStyle: {
                                        color: 'white',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    ],
                };
                this.option5 = {
                        baseOption:{
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                data: ['图文阅读','微信发文','分享转发','微信收藏'],
                                textStyle: {
                                    color: 'white',
                                    fontSize: 14
                                },
                            },
                            grid: {
                                top:'20%',
                                left: '7%',
                                right: '7%',
                                bottom:0,
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                axisLine: {
                                    lineStyle: {
                                        color: 'white'
                                    }
                                },
                                axisLabel: {
                                    fontSize: 14
                                },
                                boundaryGap: false,
                                data: res.data['article_30']['date']
                            },
                            yAxis: {
                                type: 'value',
                                splitNumber: 2,
                                axisLine: {
                                    show: false
                                },
                                axisLabel: {
                                    fontSize: 14
                                },
                            },
                            series:[{
                                name: '图文阅读',
                                type: 'line',
                                data: res.data['article_30']['int_page_read_count'],
                                color: this.linear_color
                            }],
                            textStyle: {
                                color: 'white'
                            },
                        },
                        media: [ // 媒体查询
                            {
                                query: { // 这里写规则
                                    maxWidth: 350
                                },
                                option: { // 这里写此规则满足下的 option
                                    dataZoom: [//给x轴设置滚动条
                                        {
                                            type: 'inside',
                                            realtime: true,
                                            startValue: 0,
                                            endValue: 10
                                        }
                                    ],
                                    legend: {
                                        textStyle: {
                                            color: 'white',
                                            fontSize: 8
                                        }
                                    },
                                }
                            },
                        ],
                    };
                this.option6 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}'
                        },
                        //顶部图例
                        legend: {
							orient: 'vertical',
							left: 'left',
							data:['用户数量'],
							textStyle:{
								color:'#fff',
							},
						},
                        //视觉映射组件
                        visualMap: {
                            left:'5%',
                            bottom: '5%',
                            text: ['高', '低'],
                            realtime: false,
                            calculable: true,
                            inRange: {
                                color:["#37A2DA"]
                            },
                            textStyle: {
                                color: '#fff',
                                fontSize: 14
                            }
                        },
                        geo: {
                            show: true,
                            map: "china",
                            layoutCenter: ['50%', '50%'],
                            layoutSize: '100%',
                            label: {
                                emphasis: {
                                    show: false
                                }
                            },
                            itemStyle: {
                                normal: {
                                    borderColor: 'rgba(147, 235, 248, 1)',
                                    borderWidth: 1,
                                    areaColor: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.8,
                                        colorStops: [{
                                            offset: 0,
                                            color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                                        }],
                                        globalCoord: false // 缺省为 false
                                    },
                                    shadowColor: 'rgba(128, 217, 248, 1)',
                                    shadowOffsetX: -2,
                                    shadowOffsetY: 2,
                                    shadowBlur: 10
                                },
                                emphasis: {
                                    areaColor: '#389BB7',
                                    borderWidth: 0
                                }
                            },
							tooltip:{
								show:true,
								formatter:(params)=>{
									let city = res.data['province'].filter(item=>item.name===params.name);
									return params.name + '<br/>' + city[0].value + '人';
								}
							}
                        },
                        series: [
                            {
                                //文字和标志
                                name: '城市',
                                type: 'scatter',
                                coordinateSystem: 'geo',
                                data: convertData(res.data['province']),
                                symbolSize:10,
                                label: {
                                    normal: {
                                        formatter: '{b}{@value}人',
                                        position: 'right',
                                        show: true
                                    },
                                    emphasis: {
                                        show: true
                                    }
                                },
                                itemStyle: {
                                    normal: {
                                        color: '#37A2DA'
                                    }
                                }
                            },
                            //地图点的动画效果
                            {
                                //  name: 'Top 5',
                                type: 'effectScatter',
                                coordinateSystem: 'geo',
                                data: convertData(res.data['province'].sort((a, b)=> {
                                    return b.value - a.value;
                                }).slice(0, 10)),
                                symbolSize: (val)=> {
                                    return val[2] > 1000?25:val[2] / 50;
                                },
                                showEffectOn: 'render',
                                rippleEffect: {
                                    brushType: 'stroke'
                                },
                                hoverAnimation: true,
                                itemStyle: {
                                    normal: {
                                        color: '#37A2DA',
                                        shadowBlur: 10,
                                        shadowColor: '#37A2DA'
                                    }
                                },
                                zlevel: 1
                            },
                            //飞线
                            {
                                type: 'lines',
                                name:'路径',
                                zlevel: 2,
                                effect: {
                                    show: true,
                                    period: 4, //箭头指向速度，值越小速度越快
                                    trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
                                    symbol: 'arrow', //箭头图标
                                    symbolSize: 3, //图标大小
                                },
                                lineStyle: {
                                    normal: {
                                        color: '#37A2DA',
                                        width: 0.1, //尾迹线条宽度
                                        opacity: 0.5, //尾迹线条透明度
                                        curveness: .3 //尾迹线条曲直度
                                    }
                                },
                                data: convertToLineData(res.data['province'], [107.25, 35.43])
                            },
                        ],
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                visualMap:{
                                    show:false
                                },
                            }
                        },
                    ],
                };
            },
            changeDate(){
                setInterval(()=>{
                    if(this.option4_index<this.option4_list.length-1){
                        this.option4_index++
                    }
                    else{
                        this.option4_index=0
                    }
                    if(this.option5_index<this.option5_list.length-1){
                        this.option5_index++
                    }
                    else{
                        this.option5_index=0
                    }
                    this.option4.baseOption.series=this.option4_list[this.option4_index]
                    this.option5.baseOption.series=this.option5_list[this.option5_index]
                },5000)
            },
            formatter(number) {
                const numbers = number.toString().split('').reverse()
                const segs = []
                while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                return segs.join(',').split('').reverse().join('')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/static/css/common";
</style>
